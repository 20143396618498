import "bootstrap";
import Glide from '@glidejs/glide';

/* resize header on scroll */
window.onscroll = function() {scrollFunction() };
function scrollFunction() {
    const header = document.getElementById("page-header");
    if(document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        header.classList.add('header--scrolled');
    } else {
        header.classList.remove('header--scrolled');
    }
}

/* Glide (Carousel) */
const carousels = document.querySelectorAll('.glide');
for (let i = 0; i < carousels.length; i++) {
    const glide = new Glide(carousels[i], {
        type: 'slider',
        perView: 3,
        focusAt: 0,
        gap: 30,
        breakpoints: {
            1024: {
                perView: 2,
            }
        }
    });
    glide.mount();
}

/* Accordion (Show / Hide) */
const accordions = document.querySelectorAll(".accordion");
const accordionState = "active";
accordions.forEach(function (accordion, index) {
    const toggle = accordion.querySelector('.accordion__toggle')
    const text = accordion.querySelector('.accordion__toggle .text')
    toggle.onclick = function () {
        this.classList.toggle(accordionState);
        /* Required for Table of Content Block Only */
        if (text) {
            if (text.innerHTML === "[show]") {
                text.innerHTML = "[hide]";
            } else {
                text.innerHTML = "[show]";
            }
        }
    };
});

/* Less / More (Show / Hide with Counter )*/
const lessAndMore = document.querySelectorAll(".less-more");
const lessAndMoreState = "active";
lessAndMore.forEach(function (lessMore, index) {
    let count = 4;

    function list(limit) {
        const list = lessMore.querySelector('.less-more__list').querySelectorAll('li');
        list.forEach(function (item, index) {
            if (index <= limit) {
                item.style.display = 'block';
            } else {
                item.style.display = 'none';
            }
        });
        if (list.length <= 5) {
            lessMore.querySelector('.less-more__toggle').style.display = 'none';
        }
    }

    function toggle() {
        const toggle = lessMore.querySelector('.less-more__toggle');
        toggle.onclick = function () {
            this.classList.toggle(lessAndMoreState);

            if (count === 4) {
                count = 9999;
                list(count);
            } else {
                count = 4;
                list(count);
            }
        }
    }

    list(count);
    toggle();
});

/* Mobile Menu */

const mobileMenu = document.querySelector('.mobile-menu');
if (mobileMenu) {

    function toggles() {
        const toggles = document.querySelectorAll('.mobile-menu__toggle');
        toggles.forEach(function (toggle) {
            toggle.onclick = function () {
                mobileMenu.classList.toggle('active');
            }
        });
    }

    function levels() {

        const levelOne = document.querySelector('.mobile-menu__level--1');
        const levelTwo = document.querySelector('.mobile-menu__level--2');
        const hasChildren = document.querySelector('.mobile-menu__has-children');
        const back = document.querySelector('.mobile-menu__back');

        function levelState() {
            back.classList.toggle('active');
            levelOne.classList.toggle('active');
            levelTwo.classList.toggle('active');
        }

        hasChildren.onclick = function (e) {
            e.preventDefault();
            levelState();
        }

        back.onclick = function () {
            levelState();
        }


    }

    toggles();
    levels();
}

const freeformForms = document.querySelectorAll('form[data-id]');

if (freeformForms) {
    fetch('/actions/business-logic-module/default/get-campaign-data')
        .then(response => response.json())
        .then(data => {
            freeformForms.forEach(freeformForm => {
                if (data.referrer) freeformForm.querySelector('input[name="referrer"]').value = data.referrer;
                if (data.queryArr.utm_campaign) freeformForm.querySelector('input[name="utm_campaign"]').value = data.queryArr.utm_campaign;
                if (data.queryArr.utm_content) freeformForm.querySelector('input[name="utm_content"]').value = data.queryArr.utm_content;
                if (data.queryArr.utm_medium) freeformForm.querySelector('input[name="utm_medium"]').value = data.queryArr.utm_medium;
                if (data.queryArr.utm_source) freeformForm.querySelector('input[name="utm_source"]').value = data.queryArr.utm_source;
                if (data.queryArr.utm_term) freeformForm.querySelector('input[name="utm_term"]').value = data.queryArr.utm_term;
            });
        });
}
